<template>
  <f7-page class="articleview-page">
    <template #fixed>
      <CommonNavigationComponent type="back" />
    </template>

    <section class="articleview-container">
      <div v-if="eventData?.Image" class="article-wrapper">
        <img :src="$h.getImage(eventData?.Image, 'EVENT')" :alt="$t.getTranslation('LBL_EVENT_IMAGE')" />
      </div>

      <div class="article-content">
        <h1 v-if="eventData?.Title" class="title" v-html="eventData.Title"></h1>

        <div v-if="eventData?.Description" class="content" v-html="eventData.Description"></div>

        <div class="options">
          <f7-link v-if="showShareButton" @click="showShare">
            <font-awesome-icon :icon="['far', 'share']" fixed-width />
            {{ $t.getTranslation("LBL_SHARE") }}
          </f7-link>
        </div>
      </div>
    </section>

    <ShareComponent
      v-if="eventData && eventData.EventCode !== ''"
      ref="shareComponent"
      :share-data="{
        type: 'EVENT',
        key: eventData.EventCode,
        title: eventData.Title,
        description: eventData.Description,
        qoute: eventData.Title,
        hashtags: '',
        media: $h.getImage(eventData.Image, 'EVENT'),
      }"
    />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import CommonNavigationComponent from "@/components/navigations/CommonNavigationComponent.vue";
// import ShareComponent from "@/components/ShareComponent.vue";

const CommonNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "common-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/CommonNavigationComponent.vue"));
const ShareComponent = defineAsyncComponent(() => import(/* webpackChunkName: "share" */ /* webpackMode: "lazy" */ "@/components/ShareComponent.vue"));

export default defineComponent({
  name: "EventsViewPage",
  components: {
    CommonNavigationComponent,
    ShareComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const eventData = ref(false);

    onMounted(() => {
      if (props?.f7route?.params?.code) getEventData(props?.f7route?.params?.code);
    });

    const getEventData = async (code) => {
      let ret = await get("/event/view", { EventCode: code, LanguageCode: $t.getLanguage() });
      eventData.value = ret;

      increaseView();
    };

    const increaseView = async () => {
      let eventCode = props?.f7route?.params?.code;
      store.dispatch("view/increase", { type: "EVENT", key: eventCode });
    };

    const showShareButton = ref(true);
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      showShareButton.value = false;
    }

    return {
      showShareButton,
      eventData,
    };
  },
  methods: {
    showShare() {
      this.$refs.shareComponent.showShareSheet();
    },
  },
});
</script>
